export const pages = [
    {
        id:1,
        name: "Home",
        path:"/home"
    }, 

      {
        id:3,
        name: "Drawings",
        path: "#"
    },
    {
        id:4,
        name: "Watercolor",
        path: "#"
    },
    {
        id:5,
        name: "Featured",
        path: "/featured"
    }, 
    {
        id: 6, 
        name: "Paintings", 
        path:"/paintings"
    }, 
    {
        id: 7, 
        name: "Upload Fotos",
        path:"/uploadImage"
    },

    {
        id: 8,
        name: "Filter", 
        path:"/filter"
    }
 
];

export const select = [
    {
        id: 1,
        name: "Size",
        child: [
            {
                id: 1,
                name: "small"
            },
            {
                id: 1,
                name: "medium"
            },
            {
                id: 1,
                name: "big"
            }
        ]
    },
    {
        id: 2,
        name: "Color",
        child: [
            {
                id: 1,
                name: "red"
            },
            {
                id: 2,
                name: "blue"
            },
            {
                id: 3,
                name: "yellow"
            },
            {
                id: 4,
                name: "gray"
            }
        ]
    },
    {
        id: 3,
        name: "Price",
        child: [
            {
                id: 1,
                name: "<500"
            },
            {
                id: 1,
                name: "501-1000"
            },
            {
                id: 1,
                name: ">1000"
            },
        ]
    }

];
export const size = [
    {
        id: 1,
        name: "Big"
    },
    {
        id: 2,
        name: "Medium"
    },
    {
        id: 3,
        name: "Small"
    }
];

export const color = [
    {
        id: 1,
        name:"Blue"
    },
    {
        id: 2,
        name:"Fuchsia"
    },
    {
        id: 3,
        name:"Black"
    },
    {
        id: 4,
        name:"White"
    }
]
